<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <PilotCard ref="preview" v-bind:title="title" v-bind:example="true">
          <template v-slot:title>
            <h3 class="card-title">
              <slot name="title">Edit Observation Category</slot>
            </h3>
          </template>
          <template v-slot:toolbar>
            <div>
              <router-link
                to="/observation-categories"
                v-slot="{ href, navigate }"
              >
                <a
                  :href="href"
                  class="btn btn-primary"
                  v-b-tooltip.hover
                  title="View All Categories"
                  @click="navigate"
                >
                  <i class="fas fa-list pr-0"></i>
                </a>
              </router-link>
            </div>
          </template>
          <template v-slot:body>
            <div class="row">
              <form
                @submit.prevent="submit"
                class="form col-md-8 mx-auto mt-5"
                novalidate="novalidate"
                autocomplete="false"
                id="observation_form"
              >
                <div class="pb-5">
                  <div class="form-group">
                    <label>Category Name</label>
                    <input
                      class="form-control form-control-solid form-control-lg"
                      type="text"
                      placeholder="Observation Title"
                      autocomplete="off"
                      v-model.trim="$v.name.$model"
                    />
                    <template v-if="$v.name.$error">
                      <span
                        class="form-text text-danger"
                        v-if="!$v.name.required"
                      >
                        Observation category name is required.
                      </span>
                    </template>
                    <span class="form-text text-muted"
                      >Please enter your category name.</span
                    >
                  </div>
                  <div class="form-group">
                    <label>Description</label>
                    <textarea
                      class="form-control form-control-solid form-control-lg"
                      placeholder="Description"
                      value=""
                      rows="5"
                      v-model.trim="$v.description.$model"
                    />
                    <template v-if="$v.description.$error">
                      <span
                        class="form-text text-danger"
                        v-if="!$v.description.required"
                      >
                        Observation category description is required.
                      </span>
                    </template>
                    <span class="form-text text-muted"
                      >Please describe the observation category.</span
                    >
                  </div>
                </div>

                <div class="d-flex justify-content-end border-top pt-10 pb-10">
                  <div>
                    <router-link
                      to="/observation-categories"
                      v-slot="{ navigate }"
                    >
                      <button
                        type="button"
                        class="btn btn-light-danger font-weight-bolder font-size-h5 px-15 py-4 my-3 mr-3"
                        @click="navigate"
                      >
                        Cancel
                      </button>
                    </router-link>
                    <button
                      type="submit"
                      class="btn btn-primary font-weight-bolder font-size-h5 px-15 py-4 my-3 mr-3"
                    >
                      <b-spinner
                        v-if="loading == true"
                        variant="light"
                        label="Spinning"
                      ></b-spinner>
                      <span v-if="loading == false">Update Category</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </template>
        </PilotCard>
      </div>
    </div>
  </div>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ObservationClassificationService from "@/core/services/observation-classification.service.js";
import PilotCard from "@/view/content/Card.vue";
import { required } from "vuelidate/lib/validators";
// import Swal from "sweetalert2";
export default {
  props: {
    title: String
  },
  data() {
    return {
      categoryId: "",
      name: "",
      description: "",
      show: true,
      loading: false
    };
  },
  validations: {
    name: { required },
    description: { required }
  },
  components: {
    PilotCard
  },
  methods: {
    async submit() {
      this.$v.$touch();
      this.loading = true;
      const payload = {
        name: this.name,
        description: this.description
      };

      ObservationClassificationService.update(this.categoryId, payload)
        .then(response => {
          this.makeToast(
            "success",
            "Observation Category Saved",
            response.data.message
          );

          this.loading = false;
        })
        .catch(error => {
          this.makeToast("error", "Oops", error.data.message);
          this.loading = false;
        });
    },
    makeToast(variant = null, title, message) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        solid: true
      });
    }
  },
  computed: {
    /**
     * Check if has title
     * @returns {boolean}
     */
    hasTitleSlot() {
      return !!this.$slots["title"];
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Observation Categories", route: "observation-categories" },
      { title: "Create Category" }
    ]);

    ObservationClassificationService.get(this.$route.params.id).then(
      response => {
        this.categoryId = response.data.id;
        this.name = response.data.name;
        this.description = response.data.description;
      }
    );
  }
};
</script>
